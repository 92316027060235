<template>
    <div class="main-container">
        <!-- SECTION LOGOS -->
        <section class="section-logos">
            <h1 class="title-solo">Sponsors</h1>
            <div class="logos-container">
                <div class="logo-item sponsors-item" v-for="(item, idx) in sponsors" :key="idx">
                    <a :href="item.url" target="_blank">
                    <img :src="item.img" :alt="item.alt" />
                    </a>
                </div>
            </div>
        </section>
        <!-- SECTION BECOME -->
        <section class="section-become">
            <div class="become-sep-top"></div>
            <h2>Become a Sponsor</h2>
            <div class="become-container">
                <h3>Take the opportunity to insert your logo on:</h3>
                <ul>
                    <li>The 3D Exhibition Hall panels</li>
                    <li>Our communication and promotional tools (emailings, social network posts, Motion Design promotional videos) in addition to your URL</li>
                    <li>The drop-down banner Sponsors visible from the home page</li>
                    <li>The home page of the event website</li>
                    <li>Here on the Sponsors page</li>
                </ul>
                <p class="become-baseline">
                    Become a sponsor of this event for <strong>6 000 € HT</strong>
                </p>
            </div>
      
            <div class="become-sep-bottom"></div>
        </section>

        <!-- SECTION CONTACT -->
        <section class="section-contact">
            <h2>Contact Form<br /><span class="title-focus">to become a sponsor</span> </h2>
            <ContactForm></ContactForm>
        </section>
    </div>
</template>

<script>
import ContactForm from './../components/ContactForm.vue'

export default {
    name : 'Sponsors',
    components : { ContactForm },
    data() {
        return {
            sponsors : [
                {
                img : require('@/assets/sponsors/percall.jpg'),
                alt : 'Logo Percall',
                url : 'http://www.percall.fr'
                },
                {
                img : require('@/assets/sponsors/ptc.png'),
                alt : 'Logo PTC',
                url : ' https://www.ptc.com/en/'
                },
                {
                img : require('@/assets/sponsors/synthes3d.png'),
                alt : 'Logo SYNTHES3D',
                url : 'https://www.synthes3d.com/en/'
                },
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
    .section-logos {
        background-image: url('./../assets/bg-about.png');
        background-repeat: no-repeat;
        background-position: center left;
        padding-bottom:80px;
    }

    .section-become {
        padding:180px 0;
        background-color: $main-color;
        position: relative;
    }

    .section-become h2 {
        color:#fff;
    }

    .become-sep-top {
        position: absolute;
        top:0;
        left:0;
        right:0;
        height:100px;
        background-image: url('./../assets/sep-home-exhibit-top.png');
        background-position: center bottom;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .become-sep-bottom {
        position: absolute;
        bottom:0;
        left:0;
        right:0;
        height:100px;
        background-image: url('./../assets/sep-home-exhibit-bottom-grey.png');
        background-position: center top;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .become-baseline {
        color:#fff;
        text-align: center;
        margin:0 20px;
        padding-top: 20px;
        font-size:25px;
    }

    .become-container {
        max-width: 1100px;
        width:calc(90% - 80px);
        padding:40px;
        margin:40px auto;
        border:1px solid #fff;
        border-radius:10px;
    }

    .become-container h3 {
        color:#fff;
        text-align: center;
        font-size:25px;
        font-weight:400;
    }

    .become-container ul {
        margin:0;
        padding:0;
    }

    .become-container ul li {
        color:#fff;
        font-size:20px;
        margin-bottom:20px;
        list-style: none;
        position: relative;
        left:  40px;
        margin-right: 20px;
    }

    .become-container ul li::before {
        content:'';
        width:25px;
        height:22px;
        background-image: url('./../assets/puce-white.png');
        background-position: center;
        background-size: initial;
        background-repeat: no-repeat;
        position: absolute;
        left:-40px;
        top:3px;
    }



    .section-contact {
        padding:80px 0;
        background-image: url('./../assets/bg-contact.jpg');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    @media screen and (max-width:768px) {
        .section-logos{
            padding-bottom:0;
        }

        .become-container h3 {
            font-size:20px;
        }

        
        .become-container ul li {
            font-size: 18px;
            left:20px;
        }

        .become-baseline {
            font-size:20px;
        }

        .section-contact {
            padding:40px 0;
        }
    }
</style>